<script setup>
import { useBible } from "~/composables/useBible";
import { useApp } from "~/composables/useApp";

const { loading } = useBible();
const { bibles, appVars } = useApp();
</script>

<template>
  <div class="screen-home">
    <div class="mx-4">
      <div class="select-bible-row mt-4">
        <VBookSelect
          :singleBible="bibles.singleBible"
          :items="bibles.singleBible?.location.value?.bibleModel?.booksColl"
        />
        <VChapterSelect
          :singleBible="bibles.singleBible"
          :items="bibles.singleBible?.location.value?.bibleModel?.booksColl"
        />
        <VBibleSelect
          :singleBible="bibles.singleBible"
          :items="bibles.singleBible?.location.value?.bibleModel?.booksColl"
        />
      </div>

      <div class="settings-panel mt-4">
        <VSettingsPanel />
      </div>
    </div>
    <div class="bible-wrapper">
      <VSingleBible
        :singleBible="bibles.singleBible"
        :elementId="`singleBible`"
      />
    </div>
  </div>
</template>

<style scoped>
.screen-home {
  height: 100%;
}
.bible-wrapper {
  overflow-y: hidden;
  height: calc(100% - 100px);
}
.select-bible-row {
  grid-template-columns: 119px 75px 109px;
  grid-template-columns: 37% 24% 34%;
  display: grid;
  box-sizing: border-box;
  gap: 2.5%;
}
.book-title {
  position: relative;
}
.settings-panel {
  right: 10px;
  top: 102px;
  /* position: absolute; */
  z-index: 1000;

  /* display: grid; */
  /* grid-template-columns: 1fr auto; */
  /* height: 40px; */
  /* align-items: center; */
  /* position: relative; */
  /* right: 0; */
  /* top: -5px; */
}

.chapter-name {
  width: 100%;
  font-size: 20px;
  font-weight: 600;
}
</style>
